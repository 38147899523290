

.container-v1{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

@media(min-width: 640px){
    .container-v1{
        max-width: 640px;
    }
}

@media(min-width: 768px){
    .container-v1{
        max-width: 768px;
    }
}

@media(min-width: 1024px){
    .container-v1{
        max-width: 1024px;
    }
}

@media(min-width: 1380px){
    .container-v1{
        max-width: 1380px;
    }
}







